import Head from "next/head";
import { useEvents } from "../backend/events/useEvents";
import Link from "next/link";
import AppShell from "../components/ui/AppShell";
import Icon from "../components/ui/icons/Icon";
import PageHeader from "../components/ui/headers/PageHeader";
import { useTranslation } from "next-i18next";
import { PrimaryButton } from "../components/ui/buttons/PrimaryButton";
import Skeleton from "../components/ui/skeletons/Skeleton";

export default function Events() {
  const { isLoading, error, data } = useEvents();
  const { t } = useTranslation("events");

  if (isLoading) return "Loading...";
  if (error) return "An error has occured: + " + error.message;

  return (
    <div>
      <Head>
        <title>Events</title>
        <meta name="description" content="Events" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <AppShell>
        <PageHeader
          title="Events"
          action={
            <Link href="/events/create">
              <PrimaryButton label={"New event"} />
            </Link>
          }
        />
        <div className="bg-white sm:rounded-md overflow-hidden mb-20 shadow">
          <ul role="list" className="divide-y divide-gray-200  ">
            {data.map((item) => (
              <li key={item.id} className="block p-5 hover:bg-gray-50">
                <Link key={item.id} href={`/events/${item.id}/registration`}>
                  <article aria-labelledby={"event-title" + item.id}>
                    <div className="flex justify-between">
                      <div className="flex space-x-6 items-center">
                        <div className="h-24 w-24 bg-amber-100 p-4 rounded-md flex flex-col justify-center items-center">
                          <div className="tracking-tight text-gray-600">
                            {item.start_time &&
                              t("eventMonth", {
                                startTime: new Date(item.start_time),
                              })}
                          </div>
                          <div className="text-3xl font-semibold">
                            {item.start_time &&
                              t("eventDay", {
                                startTime: new Date(item.start_time),
                              })}
                          </div>
                        </div>
                        <div className="flex-1 items-center space-y-2">
                          <h2 className="tracking-tight text-xl font-semibold text-gray-900">
                            {item.topic || <Skeleton />}
                          </h2>

                          <div className=" text-sm text-gray-500">
                            {item.start_time ? (
                              t("eventTime", {
                                startTime: new Date(item.start_time),
                              })
                            ) : (
                              <Skeleton />
                            )}
                          </div>
                          <div className="inline-flex items-center space-x-2 rounded-full text-sm   text-gray-500">
                            <Icon
                              width={16}
                              height={16}
                              icon={"tabler:brand-zoom"}
                            />
                            <div>Zoom webinar</div>
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:flex space-x-2 items-center justify-center">
                        <Icon
                          icon="fluent-mdl2:user-followed"
                          className="text-gray-400"
                        />
                        <div className="font-medium text-xl">
                          {item.guests_count}
                        </div>
                      </div>
                    </div>
                  </article>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </AppShell>
    </div>
  );
}
