import Icon from "../icons/Icon";
import React from "react";
import { twMerge } from "tailwind-merge";

export function Button({ label, icon, className, ...other }) {
  return (
    <button
      type="button"
      className={twMerge(
        `inline-flex items-center px-4 py-2 border  rounded-md
       shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2`,
        className
      )}
      {...other}
    >
      {icon && (
        <Icon icon={icon} className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      )}
      {label}
    </button>
  );
}
