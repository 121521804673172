import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosService from "../../services/axiosService";
import { getAuthorizationHeaderIfToken } from "../../utils/authToken";
import {
  createEmptyItem,
  createEmptyList,
} from "../../components/ui/skeletons/createPlaceholders";

export const QUERY_EVENTS = "events";
export const QUERY_EVENT = "event";

export const fetchEventSlugs = async () => {
  const results = await axiosService.get("/api/event-slugs");
  return results.data;
};

export const fetchEvents = async (accessToken) => {
  const results = await axiosService.get(
    "/api/events/",
    getAuthorizationHeaderIfToken(accessToken)
  );
  return results.data;
};

export const useEvents = () => {
  return useQuery([QUERY_EVENTS], () => fetchEvents(), {
    placeholderData: createEmptyList(6),
  });
};

export const fetchEvent = async (eventId) => {
  const results = await axiosService.get(`/api/events/${eventId}`);
  return results.data;
};

export const fetchEventBySlug = async (eventSlug) => {
  const results = await axiosService.get(`/api/events/?slug=${eventSlug}`);
  return results.data[0];
};

export const useEvent = (id) => {
  return useQuery([QUERY_EVENT, id], () => fetchEvent(id), {
    placeholderData: createEmptyItem(),
  });
};

export const getEventUrlFromSlug = (slug) => {
  return `${process.env.NEXT_PUBLIC_BASE_URL}/${slug}`;
};

export const useEventUrl = (eventId) => {
  const { data: event } = useEvent(eventId);
  const eventFullUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/${event.slug}`;

  return { eventFullUrl };
};

export const createEvent = async (event) => {
  const results = await axiosService.post(`/api/events/`, event);
  return results.data;
};

export const useCreateEventMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((event) => createEvent(event), {
    onSuccess: (event) => {
      queryClient.invalidateQueries([QUERY_EVENTS]);
    },
  });
};

export const publishEventPage = async (eventId) => {
  const results = await axiosService.post(`/api/events/${eventId}/publish`);
  return results.data;
};

export const usePublishEventPageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((eventId) => publishEventPage(eventId), {
    onSuccess: (event) => {
      queryClient.invalidateQueries([QUERY_EVENT, event.id]);
    },
  });
};

function deleteEvent(eventId) {
  return axiosService.delete(`/api/events/${eventId}`);
}

export const useDeleteEventMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((eventId) => deleteEvent(eventId), {
    onSuccess: (event) => {
      queryClient.invalidateQueries([QUERY_EVENTS]);
    },
  });
};
