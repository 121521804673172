import Link from "next/link";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function PageHeader({
  children,
  title,
  backButtonLabel = "Back",
  backButtonLink,
  action,
}) {
  return (
    <header className="py-6 ">
      <div>
        {backButtonLink && (
          <Link
            href="/events"
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            <ChevronLeftIcon
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {backButtonLabel}
          </Link>
        )}
      </div>
      <div className="flex justify-between">
        {title && (
          <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        )}
        <div>{action}</div>
      </div>
      {children}
    </header>
  );
}
