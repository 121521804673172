export function createEmptyList(numberOfItems) {
  return Array.apply(null, Array(numberOfItems)).map((element, index) => {
    return {
      id: index,
    };
  });
}

export function createEmptyItem() {
  return {};
}
